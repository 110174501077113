import React from 'react';
import AnalogClock from '../Subcomponents/AnalogClock';
import ActivityPopup from './ActivityPopup';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { connect } from 'react-redux';
//-- Activities component
class ActivityBlock extends React.Component{
	constructor(props, context) {
		super(props, context);
		this.state = {
			popup: ''
		}

		this.showPopup = this.showPopup.bind(this);
		this.removePopup = this.removePopup.bind(this);

	}

	showPopup() {
		document.getElementById("body").classList.add("no-scroll");
		this.setState({popup : true});
	}

	removePopup(e) {
		const el = e.target;
		if(el.getAttribute('id') === 'popup'){
			document.getElementById("body").classList.remove("no-scroll");
			this.setState({popup : false});
		}
	}

	render() {

		return (
			<div className="holder-col-4"  >
			<ReactCSSTransitionGroup
				transitionName="shifts"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={150000000}
				transitionEnterTimeout={0}
				transitionLeaveTimeout={0}>

					<div className="small-title" onClick={()=> this.props.responsiveVoice.speak(this.props.date, "Dutch Female", {volume: this.props.appData.volume})}>
						{this.props.date}
						<div className={"day-icon daynumber-"+this.props.daynumber}>

						</div>
					</div>
					<div className="full-size">
					{this.props.showPopup ? <div className="popupbutton" onClick={this.showPopup} ><i className="fa fa-info-circle"></i></div> : ''}
					{this.props.showPopup ? <ActivityPopup removePopup={this.removePopup} clients={this.props.appData.application.clients[this.props.day]} display={this.state.popup} name={this.props.name} content={this.props.popupContent} day={this.props.day} activity={this.props.id} /> : ''}
					{this.props.isWholeDay === 0 ?
					<AnalogClock hours={this.props.time.hours} minutes={this.props.time.minutes} seconds={this.props.time.seconds} />
					: ''}
					<img src={this.props.image} alt={''}/>
					</div>
					<div className="wide-title" onClick={()=> this.props.responsiveVoice.speak(this.props.name, "Dutch Female", {volume: this.props.appData.volume})}>
						{this.props.name}
					</div>

			</ReactCSSTransitionGroup>
			</div>
		)
	}
}


function mapStateToProps(state) {
    return {
        appData: state.appData,
		responsiveVoice: state.appData.responsiveVoice
    };
}


export default connect(mapStateToProps)(ActivityBlock);
