import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import MenuHeader from '../Menu/MenuHeader';
import MenuContent from '../Menu/MenuContent';
import MenuPopup from '../Menu/MenuPopup';

import { connect } from 'react-redux';

class MenuBlock extends React.Component{
	constructor(props, context) {
		super(props, context);
		this.state = {
			popup: ''
		}

		this.showPopup = this.showPopup.bind(this);
		this.removePopup = this.removePopup.bind(this);
	}

	renderHeaderIfTrue(daypart) {
		if(daypart === 'morning') {
			if(this.props.item.morning_display === true) {
				return <MenuHeader hasClass={daypart} title={this.props.item.morning_title}/>;
			}
		}
		if(daypart === 'afternoon') {
			if(this.props.item.afternoon_display === true) {
				return <MenuHeader hasClass={daypart} title={this.props.item.afternoon_title}/>;
			}
		}
		if(daypart === 'evening') {
			if(this.props.item.evening_display === true) {
				return <MenuHeader hasClass={daypart} title={this.props.item.evening_title}/>;
			}
		}
	}

	renderContentIfTrue(daypart, renderPopupButton = false) {

		if(daypart === 'morning') {
			if(this.props.item.morning_display === true) {
				return <MenuContent showPopup={this.showPopup} renderPopupButton={renderPopupButton} hasClass={daypart} image={this.props.item.morning_image} content={this.props.item.morning_content}/>;
			}
		}
		if(daypart === 'afternoon') {

			if(this.props.item.afternoon_display === true) {
				return <MenuContent showPopup={this.showPopup} renderPopupButton={renderPopupButton} hasClass={daypart} image={this.props.item.afternoon_image} content={this.props.item.afternoon_content}/>;
			}
		}
		if(daypart === 'evening') {
			if(this.props.item.evening_display === true) {
				return <MenuContent showPopup={this.showPopup} renderPopupButton={renderPopupButton} hasClass={daypart} image={this.props.item.evening_image} content={this.props.item.evening_content}/>;
			}
		}
	}

	showPopup() {
		this.setState({popup : true});
		document.getElementById("body").classList.add("no-scroll");
	}

	removePopup(e) {
		const el = e.target;
		if(el.getAttribute('id') === 'popup'){
			document.getElementById("body").classList.remove("no-scroll");
			this.setState({popup : false});
		}

	}

	render() {
		let renderPopupMobile = false;
		return (
			<div className="col-3-holder">
				<ReactCSSTransitionGroup transitionName="contentblock" transitionAppear={true} transitionEnter={false} transitionLeave={false} transitionAppearTimeout={150000000} transitionEnterTimeout={0} transitionLeaveTimeout={0}>
					<div id="daily-food" className={"visible-md has-columns-" + this.props.item.columns} data-id="124">
					    <div id="header" className={this.props.headerClass} >
					        <div className="inner-header contentTitle ">
					        	{this.renderHeaderIfTrue('morning')}
					        	{this.renderHeaderIfTrue('afternoon')}
					        	{this.renderHeaderIfTrue('evening')}
					        </div>
					    </div>
					    <div id="food-content">
					        <div className="block-content">
					        	<MenuPopup removePopup={this.removePopup} day={this.props.day} clients={this.props.appData.application.clients[this.props.day]} display={this.state.popup} menu={this.props.item} />
					        	{this.props.item.showPopup ? <div  className="popupbutton" ><i  onClick={this.showPopup} className="fa fa-info-circle" aria-hidden="true"></i></div> : ''}
					        	{this.renderContentIfTrue('morning')}
					        	{this.renderContentIfTrue('afternoon')}
					        	{this.renderContentIfTrue('evening')}
					        </div>
					    </div>
					</div>

					<div id="daily-food-mobile" className={"visible-xs has-columns-" + this.props.item.columns} data-id="124">
					    <div id="mobile-header">
					        <div className="inner-header contentTitle ">
					        	<MenuPopup removePopup={this.removePopup} day={this.props.day} clients={this.props.appData.application.clients[this.props.day]} display={this.state.popup} menu={this.props.item} />


										{renderPopupMobile = this.props.item.showPopup ? true : false}

					        	{this.renderHeaderIfTrue('morning')}
					        	{this.renderContentIfTrue('morning', renderPopupMobile)}
					        	{this.renderHeaderIfTrue('afternoon')}
					        	{this.renderContentIfTrue('afternoon', renderPopupMobile)}
					        	{this.renderHeaderIfTrue('evening')}
					        	{this.renderContentIfTrue('evening', renderPopupMobile)}
					        </div>
					    </div>

					</div>
				</ReactCSSTransitionGroup>
		    </div>


		)
	}
}

function mapStateToProps(state) {
    return {
        appData: state.appData,
		responsiveVoice: state.appData.responsiveVoice
    };
}


export default connect(mapStateToProps)(MenuBlock);
