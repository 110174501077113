import { combineReducers } from 'redux';
import AppDataReducer from './AppDataReducer';
import GroupReducer from './GroupReducer';
import ApiUrlReducer from './ApiUrlReducer';
const rootReducer = combineReducers({
  appData: AppDataReducer,
  groups: GroupReducer,
  apiUrl: ApiUrlReducer

});

export default rootReducer;