import React from 'react';
import Marquee from '../Header/Marquee';
import RouteIcon from '../Subcomponents/RouteIcon';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'

class Header extends React.Component{
	constructor(props, context) {
		super(props, context);

		this.state = {
			buttonActive: '',
			volumeFa: 'fa fa-volume-up'
		}
	}

	buttonClick() {
		let buttonClass = (this.state.buttonActive === 'active') ? '' : 'active';

		this.setState({buttonActive: buttonClass})
	}

	setVoiceVolume() {
		let volumeChange = (this.props.appData.volume === 1) ? 0 : 1;
		
		this.props.appData.volume = volumeChange;

		let volumeClass = (volumeChange === 1) ? 'fa fa-volume-up' : 'fa fa-volume-off';

		this.setState({volumeFa: volumeClass})
	}

	chooseGroup() {
		localStorage.clear();
		window.location.reload();
	}

	render() {
		const d = new Date();
		const dayNumber = d.getDay();
		let dayNum = 1;
		if(dayNumber === 0) {
			dayNum = 7;
		}
		else{
			dayNum = dayNumber;
		}

		return (

			<header>
	            <img id="logo" src={"//files.smartdevelopment.nl/"+this.props.client+"/main/logo.png"} alt={''}/>
	            <div className="platformHolder">
		            {this.props.appData.application.showSpotify ? parseInt(this.props.appData.application.showSpotify, 10) === 1 ? <a><i className="fa fa-spotify" onClick={this.props.enlargeSpotify}></i></a>: '' : ''}
		            {this.props.appData.application.showNetflix ? parseInt(this.props.appData.application.showNetflix, 10) === 1 ? <NavLink exact to="/netflix"><i className="fa fa-tv"></i></NavLink> : '' : ''}
	           	</div>

	           	{window.location.href.match("backend") || this.props.appData.application.show_groupchoice === '1' ?
	           	<div className="groupChoice">
		           	<button onClick={() => this.chooseGroup()}>
		           		<i className="fa fa-building"></i>
		           		<span>{localStorage.activeGroupName}</span>
		           	</button>
	           	</div>
	           	: ''}

	            <RouteIcon class={this.props.appData.currentComponent} iconSrc={this.props.appData.routeIcon} routeName={this.props.appData.routeName} key={this.props.appData.routeName}/>
	               
	            <div id="text-field">
	            	<Marquee className="marquee" />
	            </div>

	            <button className={"menu-voice "} onClick={() => this.setVoiceVolume()}>
	            	<i className={this.state.volumeFa + " fa-lg"}></i>
	            </button> 

	            <button className={"menu-button " + this.state.buttonActive} onClick={() => this.buttonClick()}>
	                <span className="menu-icon"></span>
	            </button>  

	            {this.props.appData.routeIcon ? 
	            <nav id="header-menu" className={(this.state.buttonActive === 'active') ? 'open' : ''}>
                	<ul>
                		<NavLink exact to="/" activeClassName="active" onClick={() => this.props.voice.speak("Start", "Dutch Female", {volume: this.props.appData.volume})} className={this.props.currentComponent === 'home' ? 'active' : ''}>
		                    <li className="button" id="home" data-url="/site/index">
		                        <img src={"//files.smartdevelopment.nl/"+this.props.client+"/wonen/icons/home.png"} alt={''}/>
		                        <span className="title">Home</span>
		                    </li>
	                    </NavLink>
	                    <NavLink to={"/menu/" + dayNum} onClick={() => this.props.voice.speak("Eten", "Dutch Female", {volume: this.props.appData.volume})} activeClassName="active" className={this.props.currentComponent === 'menu' ? 'active' : ''}>
		                    <li className="button animate slide-from-bottom delay-15 in-view" id="food" data-url="/site/food">
		                        <img src={"//files.smartdevelopment.nl/"+this.props.client+"/wonen/icons/food.png"} alt={''}/>
		                        <span className="title">Eten</span>
		                    </li>
		                </NavLink>
		                <NavLink to={"/shifts/" + dayNum} onClick={() => this.props.voice.speak("Rooster", "Dutch Female", {volume: this.props.appData.volume})} activeClassName="active" className={this.props.currentComponent === 'shifts' ? 'active' : ''}>
		                    <li className="button animate slide-from-bottom delay-20 in-view" id="present" data-url="/site/present">
		                        <img src={"//files.smartdevelopment.nl/"+this.props.client+"/wonen/icons/present.png"} alt={''}/>
		                        <span className="title">Rooster</span>
		                    </li>
		                </NavLink>
		                <NavLink to={"/tasks/" + dayNum} onClick={() => this.props.voice.speak("Taken", "Dutch Female", {volume: this.props.appData.volume})} activeClassName="active" className={this.props.currentComponent === 'tasks' ? 'active' : ''}>
		                    <li className="button animate slide-from-bottom delay-25 in-view" id="tasks" data-url="/site/tasks">
		                        <img src={"//files.smartdevelopment.nl/"+this.props.client+"/wonen/icons/tasks.png"} alt={''}/>
		                        <span className="title">Taken</span>
		                    </li>
	                    </NavLink>
	                    <NavLink to="/activities" activeClassName="active" onClick={() => this.props.voice.speak("Activiteiten", "Dutch Female", {volume: this.props.appData.volume})} className={this.props.currentComponent === 'activities' ? 'active' : ''}>
		                    <li className="button animate slide-from-bottom delay-30 in-view" id="activities" data-url="/site/activities">
		                        <img src={"//files.smartdevelopment.nl/"+this.props.client+"/wonen/icons/activities.png"} alt={''}/>
		                        <span className="title">Activiteiten</span>
		                    </li>
	                    </NavLink>

	                    {this.props.isUserLoggedIn ? 
	                    	<NavLink to="/gebruiker" activeClassName="active" onClick={() => this.props.voice.speak("Uitloggen", "Dutch Female", {volume: this.props.appData.volume})} className={this.props.currentComponent === 'activities' ? 'active' : ''}>
			                    <li className="button animate slide-from-bottom delay-30 in-view" id="logout" data-url="/site/activities">
			                        <img src={"//files.smartdevelopment.nl/"+this.props.client+"/wonen/icons/calendar.png"} alt={''}/>
			                        <span className="title">Uitloggen</span>
			                    </li>
		                    </NavLink>
	                	: <NavLink to="/gebruiker" activeClassName="active" onClick={() => this.props.voice.speak("Mijn agenda", "Dutch Female", {volume: this.props.appData.volume})} className={this.props.currentComponent === 'activities' ? 'active' : ''}>
			                    <li className="button animate slide-from-bottom delay-30 in-view" id="logout" data-url="/site/activities">
			                        <img src={"//files.smartdevelopment.nl/"+this.props.client+"/wonen/icons/calendar.png"} alt={''}/>
			                        <span className="title">Mijn agenda</span>
			                    </li>
		                    </NavLink>}
	                </ul>
	            </nav>
	            : ''}
        	</header>
		)
	}
}

function mapStateToProps(state) {
    return {
        appData: state.appData,
        client: state.appData.client,
        voice: state.appData.responsiveVoice
    };
}




export default connect(mapStateToProps)(Header);