import React from 'react';
import { connect } from 'react-redux';

class Spotify extends React.Component{

  componentDidMount() {
    const listURL = this.props.spotifyListUrl ? this.props.spotifyListUrl : 'https://open.spotify.com';
    document.querySelector("#iframe").innerHTML = '<iframe src="'+ listURL +'" width="300" height="380" allow="encrypted-media" frameBorder="0" allowTransparency="true"></iframe>';
  }

  componentDidUpdate(prevProps) {
    if(this.props.spotifyListUrl !== prevProps.spotifyListUrl) {
      const listURL = this.props.spotifyListUrl ? this.props.spotifyListUrl : 'https://open.spotify.com';
    document.querySelector("#iframe").innerHTML = '<iframe src="'+ listURL +'" width="300" height="380" allow="encrypted-media" frameBorder="0" allowTransparency="true"></iframe>';
    }
  }
  render() {

      return (
        <div className={"platform spotify " + this.props.class}>
         <div id="iframe"></div>

        </div>
      );
  }
}
function mapStateToProps(state) {
    return {
        appData: state.appData
    };
}
export default connect(mapStateToProps)(Spotify);
