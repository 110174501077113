import React from 'react';
import DayList from '../Subcomponents/DayList';
import ShiftBlock from '../Shifts/ShiftBlock';
import WeekSchedule from '../Shifts/WeekSchedule';
import EmptyBlock from './EmptyBlock';
import { connect } from 'react-redux';
import {setIconAndName, setCurrentComponent} from '../../actions';

class Shifts extends React.Component{
	componentDidMount() {
		this.props.setCurrentComponent({currentComponent:'shifts'});
		this.props.setIconAndName({routeName:'Rooster', routeIcon:'//files.smartdevelopment.nl/'+this.props.client+'/wonen/icons/present.png'});
	}

	renderBlocks(blocks, from, to) {
		let aItems = [];
		let i = 1;
		for (let prop in blocks) {
			if(i <= to && i >= from) {
			    if (blocks.hasOwnProperty(prop)) {
			        let item = blocks[prop];
			        aItems.push(this.renderItem(item));
			    }
			}
			i += 1;
		}
		return aItems;

	}


	renderItem(item) {
        let colClass = parseInt(this.props.appData.application.extendWeekBlocks, 8) === 1 ? '3' : '4';
        return <ShiftBlock item={item} key={item.id} colClass={colClass}/>
	}

	renderContent() {
		if(this.props.match.params.day === 'week') {
			if(this.props.appData.application.shifts) {
				return  <WeekSchedule shifts={this.props.appData.application.shifts} />
			}else{
				return (
					<div>
				        <div className="row no-margin-top">
				        	<EmptyBlock type="rooster" />
				        </div>
				    </div>
				)
			}

		}else{
            let fromFirst = 1;

            let toFirst = parseInt(this.props.appData.application.extendWeekBlocks, 8) === 1 ? 4 : 3;
            let fromSecond = parseInt(this.props.appData.application.extendWeekBlocks, 8) === 1 ? 5 : 4;
            let toSecond = parseInt(this.props.appData.application.extendWeekBlocks, 8) === 1 ? 8 : 6;
			let fromThird = parseInt(this.props.appData.application.extendWeekBlocks, 8) === 1 ? 9 : 7;
			let toThird = parseInt(this.props.appData.application.extendWeekBlocks, 8) === 1 ? 16 : 12;
			if(this.props.appData.application.shifts[this.props.match.params.day]){
				return(
					<div id="shiftsBody">
				        <div className="row">
				            {this.renderBlocks(this.props.appData.application.shifts[this.props.match.params.day], fromFirst, toFirst)}
				        </div>
				        <div className="row">
				            {this.renderBlocks(this.props.appData.application.shifts[this.props.match.params.day], fromSecond, toSecond)}
				        </div>
						<div className="row">
							{this.renderBlocks(this.props.appData.application.shifts[this.props.match.params.day], fromThird, toThird)}
						</div>
			        </div>
				)
			}else{
				return (
					<div>
				        <div className="row no-margin-top">
				        	<EmptyBlock type="rooster" />
				        </div>
				    </div>
				)
			}
		}
	}

	render() {
		return (

			<div class="content-main">
			    <DayList color="green" basePath="/shifts"/>
			    <div id="present">
			    	{this.renderContent()}

			     </div>
			</div>
		)
	}
}


function mapStateToProps(state) {
    return {
        appData: state.appData,
        client: state.appData.client,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIconAndName: data => dispatch(setIconAndName(data)),
        setCurrentComponent: data => dispatch(setCurrentComponent(data)),

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Shifts);
