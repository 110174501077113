import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; 

class RouteIcon extends React.Component{
	render() {
		
		return (
			
	            <div id="page-icon">
	            		
	            	<ReactCSSTransitionGroup transitionName="topToBottom" transitionAppear={true} transitionEnter={true} transitionLeave={true} transitionAppearTimeout={1500} transitionEnterTimeout={0} transitionLeaveTimeout={0}>
	  
		                <div className={"inner-icon " + this.props.class}>
		                	<img src={this.props.iconSrc} alt={''}/>
		                	  <svg 
		                	  	version="1.1" 
		                	  	xmlns="http://www.w3.org/2000/svg" 
		                	  	x="0px" y="0px"
								width="200.01px" 
								height="200.01px" 
								viewBox="0 0 200.01 200.01" 
								>
								<path fill="#13B8D3" d="M190.634,33.073C171.006,12.36,144.021-0.684,107.251,1.176C52.838,3.927,7,47.661,7,102.146
								  c0,40.534,21.494,74.187,56.262,88.725c10.916,5.019,23.051,7.839,35.852,7.839c1.483,0,2.958-0.038,4.422-0.112
								  c3.78-0.007,7.314-0.44,10.636-1.23c40.274-7.123,70.874-42.272,70.874-84.591c0-21.163-7.665-40.527-20.351-55.501L190.634,33.073z
								  "/>
								</svg>
		                	<span className="icon-title">{this.props.routeName}</span>
		                </div>
	                 </ReactCSSTransitionGroup>
	            </div>
	       
		)
	}
}

export default RouteIcon;