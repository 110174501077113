import React from 'react';
import { connect } from 'react-redux';
import {setClientToMenu} from '../../actions';


class Client extends React.Component{

	constructor(context, props) {
		super(context, props);

		this.setClientToMenu = this.setClientToMenu.bind(this);
	}
	setClientToMenu() {
		this.props.setClientToMenu({menuId: this.props.menu_id, clientId: this.props.client.id, dayId: this.props.day})

		var formData = new FormData();
			this.props.responsiveVoice.speak((this.props.client.toMenu[this.props.menu_id] ? 'Ik eet niet mee!' : 'Ik eet mee!'), "Dutch Female", {volume: this.props.appData.volume})

        formData.append('clientId', this.props.client.id);
        formData.append('menuId', this.props.menu_id);


        fetch(this.props.apiUrl + 'setclienttomenu', {
            method: 'POST',
            headers: ({
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin,Headers'


            }),
            body: formData
        });
	}

	render () {

		return (
		 	<li id="client-holder-2" className={"client-to-menu "  +  this.props.client.toMenu[this.props.menu_id]} >
				<div style={{float : 'left', color : 'black'}} onClick={()=> this.props.responsiveVoice.speak(this.props.client.name, "Dutch Female", {volume: this.props.appData.volume})}>{this.props.client.name}</div>
				<span className="inner-button" onClick={this.setClientToMenu}>
					{this.props.client.toMenu[this.props.menu_id] ? 'Ik eet mee!' : 'Ik eet niet mee!' }
				</span>
			</li>

		)
	}
}


function mapStateToProps(state) {
    return {
        appData: state.appData,
        apiUrl: state.apiUrl.ApiUrl,
		responsiveVoice: state.appData.responsiveVoice
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setClientToMenu: data => dispatch(setClientToMenu(data)),

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Client);
