import React from 'react';

class AnalogClock extends React.Component{
	getMinuteRotation(minutes) {
		// 180 = top
		// minutes = 60
		let rotation = (360 / 60) * minutes;
		rotation += 180;
		return {transform : 'rotate('+rotation+'deg)'}
	}

	getSecondRotation(seconds) {
		// 180 = top
		// minutes = 60
		let rotation = (360 / 60) * seconds;
		rotation += 180;
		return {transform : 'rotate('+rotation+'deg)'}
	}

	getHourRotation(hours, minutes) {
		let rotation = (360 / 12) * hours;
		let minuteRotation = (360 / 60) * minutes / 12;

		rotation += 180;
		rotation += minuteRotation;

		return {transform : 'rotate('+rotation+'deg)'}
	}

	render() {
		return (
			<div id="clock" >
				<div id="hour-12">12</div>
				<div id="hour-3">3</div>
				<div id="hour-6">6</div>
				<div id="hour-9">9</div>
	            <div id="minutes">
	            	<div className="pointer" style={this.getMinuteRotation(this.props.minutes)}></div>
	            </div>
	            <div id="hours">
	            	<div className="pointer" style={this.getHourRotation(this.props.hours, this.props.minutes)}></div>
	            </div>
	            <div id="seconds">
	            	<div className="pointer" style={this.getSecondRotation(this.props.seconds)}></div>
	            </div>
        	</div>
		)
	}
}

export default AnalogClock;