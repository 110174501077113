import React from 'react';
import { connect } from 'react-redux';
import {setIconAndName, setCurrentComponent} from '../../actions';
class LoginForm extends React.Component{

	constructor(context, props) {
		super(context, props);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.logOut = this.logOut.bind(this);

		this.state = {
			errorMessage: false
		}

	}

	componentDidMount() {
		let userIdSet = false;
		if(localStorage.getItem('user_id')) {
			userIdSet = true;
		}
		this.setState({
			userIdSet: userIdSet 
		})

		this.props.setCurrentComponent({currentComponent:'loginform'});
		this.props.setIconAndName({routeName:'Account', routeIcon:'//files.smartdevelopment.nl/'+this.props.client+'/wonen/icons/calendar.png'});
	}
    handleSubmit(e) {
    	e.preventDefault();

    	const postData = {
            username: this.refs.username.value,
            password: this.refs.password.value
        }



        fetch(this.props.apiUrl + 'userlogin', {
                method: 'POST',
                headers: ({
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Origin,Headers',
                    'Content-Type': 'application/json',

                }),
                body: JSON.stringify(postData)
            }).then((response) => {
                return response.json();
            })
            .then((parsedData) => {
            	
            	let userId = false;
           		if(parsedData.userid === false) {
           			//-- Show message
           			this.setState({errorMessage: 'Deze combinatie van gebruikersnaam en wachtwoord klopt niet.'})
           		}else{
           			userId = parsedData.userid;
           			localStorage.setItem('user_id', userId);
           			this.setState({
			    		userIdSet:true
			    	});

			    	this.props.setUserLoggedIn();
           			this.props.fetchData();
           		}

            });
    }

    logOut() {
    	localStorage.removeItem('user_id');
    	this.setState({
    		userIdSet:false
    	})
    	this.props.setUserLoggedOut();
    	this.props.fetchData();
    }
	render () {

		if(this.state.userIdSet) {
			return (
				<div id="loginForm">
					<div className="input-group">
						<div onClick={this.logOut}  className="logoutbutton">Uitloggen</div>
					</div>
				</div>
			)
		}
		const className = this.state.errorMessage !== false ? 'display' : '';
		return (
				

					
		          <form id="loginForm" action="" method="post" onSubmit={this.handleSubmit}>
		          <div className={"error-message " + className}>

						{this.state.errorMessage ? this.state.errorMessage  : ''}
					</div>
                    <div className="input-group">
                    	<label>Gebruikersnaam</label>
                    	<input name="username" defaultValue={this.props.match.params.username} type="text" ref="username" placeholder="Voer je gebruikersnaam in" />
                    </div>

                    <div className="input-group">
                    	<label>Wachtwoord</label>
                    	<input name="username" type="password" ref="password" placeholder="Voer je wachtwoord in" />
                    </div>

                    <div className="input-group">
                    	<input type="submit" placeholder="Inloggen" value="Inloggen" />
                    </div>
                  </form>
               
		)
	}
}


function mapStateToProps(state) {
    return {
        appData: state.appData,
        apiUrl: state.apiUrl.ApiUrl,
        client: state.appData.client,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        	setIconAndName: data => dispatch(setIconAndName(data)),
        	setCurrentComponent: data => dispatch(setCurrentComponent(data)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);