import React from 'react';
import ForecastWeekDay from '../Forecast/ForecastWeekDay';
import {connect} from 'react-redux';
class ForecastWeekSchedule extends React.Component{

	renderBlocks(forecast) {


		const d = new Date();
		const dayNumber = d.getDay();
		let dayNum = 1;
		if(dayNumber === 0) {
			dayNum = 7;
		}
		else{
			dayNum = dayNumber;
		}

		const today = dayNum;
		const startAt = today - 1;

		const aDays = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'];
		let schedule = [];
		for(let i = startAt; i <= 6; i++) {

			schedule.push(<ForecastWeekDay onClick={()=> this.props.responsiveVoice.speak(aDays[i], "Dutch Female", {volume: this.props.appData.volume})} key={i} dayId={i+1} day={aDays[i]} forecastForDay={forecast[i+1]} />)
		}

		const restForLoop = 7 - (7 - startAt) - 1;

		for(let i = 0; i <= restForLoop; i++) {

			schedule.push(<ForecastWeekDay onClick={()=> this.props.responsiveVoice.speak(aDays[i], "Dutch Female", {volume: this.props.appData.volume})} key={i} dayId={i+1} day={aDays[i]} forecastForDay={forecast[i+1]} />)
		}

		return schedule;
	}


	render() {

		return (
			<div>
				{this.props.forecast ? this.renderBlocks(this.props.forecast) : ''}
            </div>
		)
	}
}

function mapStateToProps(state) {
    return {
        appData: state.appData,
		responsiveVoice: state.appData.responsiveVoice
    };
}


export default connect(mapStateToProps)(ForecastWeekSchedule);
