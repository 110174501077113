import React from 'react';
import { connect } from 'react-redux';

class EmptyBlock extends React.Component{

	render() {

		return (
			<div className="empty-block" onClick={()=> this.props.responsiveVoice.speak('Kies een video aan de rechterkant.', "Dutch Female", {volume: this.props.appData.volume})}>
				<div className="inner">
					Kies een video aan de rechterkant.
				</div>
		    </div>
		)
	}
}

const mapStateToProps = state => {
    return {
        appData: state.appData,
        responsiveVoice: state.appData.responsiveVoice
    };
}

export default connect(mapStateToProps)(EmptyBlock);