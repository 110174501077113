import React from 'react';
import { connect } from 'react-redux';
import EmptyBlock from './EmptyBlock';

class Karaoke extends React.Component{

    constructor(props) {
        super(props);

        this.serverKey = 'AIzaSyB-ByP9AYxb9nDhVDmZpuqX2hIDSYjyFPs';
        this.browserKey = 'AIzaSyB7m_w5yE5mXcYIEQ658WKBA-XeVasUW2o';
        this.channelID = 'UC_NxTqRAj2CGXqA8g4SgyEw';
        this.playlistId = 'PLO7bkQNCTQBvnqaOQF_qTMuWKh4nGC0NN';

        this.getResults = this.getResults.bind(this);
        this.setVideoState = this.setVideoState.bind(this);
        this.renderItems = this.renderItems.bind(this);

        this.state = {
            items: []
        }
    }


    getResults = () => {
        const fetchUrl = 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&videoEmbeddable=true&playlistId='+this.playlistId+'&key='+this.browserKey;

        fetch(fetchUrl)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
        })
        .then((responseData) => {
            this.setState({items : responseData.items});

        })
        .catch(() => {
            //this.props.appDataFailure(null);
        });
    }



    renderVideoById = () => {
        return <iframe width="560" height="315" src={"https://www.youtube.com/embed/"+this.state.currentVideoId} frameborder="0" autoplay="1"></iframe>
    }

    componentDidMount = () => {
        this.getResults();
    }

    setVideoState = (videoId, title) => {
        this.setState({currentVideoId : videoId})

        this.props.responsiveVoice.speak(title, "Dutch Female", {volume: this.props.appData.volume})
    }

    renderItems = item => {
        if(item.snippet.title === 'Deleted video') {
            return;
        }
        return(
            <div className="karaoke-item" key={item.etag} onClick={()=>{this.setVideoState(item.snippet.resourceId.videoId, item.snippet.title)}}>
                {item.snippet.title}
            </div>
        )
    }

    render = () => {
        return (
            <div className="platform karaoke">
                <div className="currently-playing">
                    {this.state.currentVideoId ? this.renderVideoById() : <EmptyBlock />}
                </div>
                <div className="items-right">

                    {this.state.items ?  this.state.items.map(this.renderItems) : ''}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        appData: state.appData,
        responsiveVoice: state.appData.responsiveVoice
    };
}

export default connect(mapStateToProps)(Karaoke);
