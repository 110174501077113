import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
class EmptyBlock extends React.Component{

	getDayFromNumber(num) {
		switch(parseInt(num, 10)) {
			case 1:
				return 'Maandag';
			case 2:
				return 'Dinsdag';
			case 3:
				return 'Woensdag';
			case 4:
				return 'Donderdag';
			case 5:
				return 'Vrijdag';
			case 6:
				return 'Zaterdag';
			case 7:
				return 'Zondag';
			default:
				return '';
		}
	}
	render() {

		return (
			<div className="col-3-holder">
				<ReactCSSTransitionGroup transitionName="contentblock" transitionAppear={true} transitionEnter={false} transitionLeave={false} transitionAppearTimeout={150000000} transitionEnterTimeout={0} transitionLeaveTimeout={0}>
					<div id="daily-food" className={"visible-md has-columns-1"} data-id="124">
					    <div id="header" className={this.props.headerClass} >
					        <div className="inner-header contentTitle " onClick={()=> this.props.responsiveVoice.speak(this.getDayFromNumber(this.props.weekDay), "Dutch Female", {volume: this.props.appData.volume})}>
					        	{this.getDayFromNumber(this.props.weekDay)}
					        </div>
					    </div>
					    <div id="food-content">
					        <div className="block-content" onClick={()=> this.props.responsiveVoice.speak("Er is nog geen "+this.props.type+"bekend vandaag.", "Dutch Female", {volume: this.props.appData.volume})}>
					        	Er is nog geen {this.props.type} bekend vandaag.
					        </div>
					    </div>
					</div>

					<div id="daily-food-mobile" className={"visible-xs has-columns-1"} data-id="124">
					    <div id="mobile-header">
					        <div className="inner-header contentTitle ">
					        	<div className={"morning header-el"}>Geen {this.props.type}</div>

					        	<div className={"morning content-el"}>
					                <div className="inner">
					                	<div>Er is nog geen {this.props.type} bekend vandaag</div>


					                </div>
					            </div>
					        </div>
					    </div>

					</div>
				</ReactCSSTransitionGroup>
		    </div>


		)
	}
}
function mapStateToProps(state) {
    return {
    	appData: state.appData,
        responsiveVoice: state.appData.responsiveVoice
    };
}
export default connect(mapStateToProps)(EmptyBlock);
