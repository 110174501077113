import React from 'react';
import AnalogClock from '../Subcomponents/AnalogClock';
import HomeBlock from '../Home/HomeBlock';
import { connect } from 'react-redux';
import {setIconAndName, setCurrentComponent} from '../../actions';
import Spinner from '../Subcomponents/Spinner';
import { NavLink } from 'react-router-dom'

class Home extends React.Component{
    constructor(props) {
        super(props);

        this.t = false;
    }
    isLast(col) {
        let data = this.props.appData.application.home;
        if(parseInt(col, 10) === 6 && parseInt(data.colClass, 10) === 6) {
            return ' last';
        }

        if(parseInt(col, 10) === 4 && parseInt(data.colClass, 10) === 4) {
            return ' last';
        }

        return '';
    }


    componentDidMount () {
        this.props.setCurrentComponent({currentComponent:'home'});
        this.props.setIconAndName({routeName:'Home', routeIcon:'//files.smartdevelopment.nl/'+this.props.client+'/wonen/icons/home.png'});
    }

    speakTime() {
        var hours = this.props.appData.hours;

        if (this.props.appData.hours === 0)
        {
            hours = 12;
        }
        else if (this.props.appData.hours >= 13) {
            hours = this.props.appData.hours - 12;
        }

        return hours + ' uur ' + this.props.appData.minutes;
    }

    render() {

        let data = this.props.appData.application.home;

        return (
            <div id="home">
                <div className="row" >
                    <div className={'home col-' + data.colClass} id="day" onClick={()=> this.props.responsiveVoice.speak(data.day, "Dutch Female", {volume: this.props.appData.volume})}>
                        <span className="block-title">{data.day ? 'Dag' : ''}</span>
                        <span className={"block-content " + (data.showDayColors === "1" ? data.day : '')}>{data.day ? data.day : <Spinner />}</span>

                        {data.day ? <div className={"day-image dayname-" + data.day.toLowerCase()}></div> : ''}
                    </div>
                    <div className={'home col-' + data.colClass + this.isLast(6)} id="time" onClick={()=> this.props.responsiveVoice.speak(this.speakTime(), "Dutch Female", {volume: this.props.appData.volume})}>
                        <span className="block-title">Tijd</span>
                        <span className="block-content" >
                            <div className="clock-hold">
                                <AnalogClock hours={this.props.appData.hours} minutes={this.props.appData.minutes} seconds={this.props.appData.seconds} />
                            </div>
                            <div id="timeholder">{this.props.appData.hours}:{this.props.appData.minutes}</div>
                        </span>
                    </div>

                    {data.colClass === 4 ? <HomeBlock data={data.blockTopRight}/> :  ''}
                </div>
                <div className="row no-margin-top">
                    <div className={'home col-' + data.colClass} id="date" onClick={()=> this.props.responsiveVoice.speak(data.date, "Dutch Female", {volume: this.props.appData.volume})}>
                        <span className="block-title  ">{data.date ? 'Datum' : ''}</span>
                        <span className="block-content">
                            {data.date ? data.date : <Spinner />}
                            {data.showPresence === 1 ? <div id="sub-title" className="visible sub-title-second" onClick={()=> this.props.responsiveVoice.pause()}><NavLink to={'/present'}>Aanwezigheid cliënten</NavLink></div> : ''}
                        </span>
                    </div>
                    <div className={'home col-' + data.colClass + this.isLast(6)} id="weather" onClick={()=> this.props.responsiveVoice.speak(data.weather.temperature + ' graden celcius, ' + data.weather.text, "Dutch Female", {volume: this.props.appData.volume})}>
                        <span className="block-title">{data.weather.temperature ? 'Weer' : ''}</span>
                        <span className="block-content">
                            <div id="temperature"><i className={"owf owf-"+data.weather.weatherIcon}></i>{data.weather.temperature ? data.weather.temperature + '°' : <Spinner />}</div>
                            <div id="sub-title" className="visible">{data.weather.text}</div>
                            {data.showForecast === 1 ? <div id="sub-title" className="visible sub-title-second" onClick={()=> this.props.responsiveVoice.pause()}><NavLink to={'/forecast'}>Weersvoorspelling bekijken</NavLink></div> : ''}
                        </span>
                        <div id="temp-icon">

                        </div>
                    </div>
                    {data.colClass === 4 ? <HomeBlock data={data.blockBottomRight}/> :  ''}
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        appData: state.appData,
        responsiveVoice: state.appData.responsiveVoice,
        client: state.appData.client,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIconAndName: data => dispatch(setIconAndName(data)),
        setCurrentComponent: data => dispatch(setCurrentComponent(data)),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
