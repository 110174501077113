import React from 'react';
import { connect } from 'react-redux';

class ForecastWeekDay extends React.Component{
	constructor(props) {
		super(props);

		this.renderForecast = this.renderForecast.bind(this);
	}
	renderForecast() {
		let item = this.props.forecastForDay;

		return (
			<div className="schedule-item-week" onClick={()=> this.props.responsiveVoice.speak(item.temperature + ' graden celsius', "Dutch Female", {volume: this.props.appData.volume})}>
				<i className={"owf owf-"+item.icon}></i>
				<h4 >{item.temperature}°</h4>
			</div>
		)
	}

	render() {
		return (
			<div className="WeekDay">
				<div className={"header color-day-"+this.props.dayId} onClick={()=> this.props.responsiveVoice.speak(this.props.day, "Dutch Female", {volume: this.props.appData.volume})} >
					<div className="background-icon"></div>
					<h2 >{this.props.day}</h2>
				</div>

				{/* this.props.forecastForDay ? Object.keys(this.props.forecastForDay).map(this.renderForecast) : '' */}

				{this.renderForecast()}
            </div>
		)
	}
}

function mapStateToProps(state) {
    return {
    	appData: state.appData,
        responsiveVoice: state.appData.responsiveVoice
    };
}
export default connect(mapStateToProps)(ForecastWeekDay);
