import React from 'react';
import {NavLink} from 'react-router-dom';
import { connect } from 'react-redux';

class WeekDay extends React.Component{
	constructor(props) {
		super(props);

		this.renderSchedule = this.renderSchedule.bind(this);
	}
	renderSchedule(key) {
		let item = this.props.schedulesForDay[key];
		return (
			<div className="schedule-item-week" key={key} onClick={()=> this.props.responsiveVoice.speak(item.employee + ', ' + item.title, "Dutch Female", {volume: this.props.appData.volume})}>

				<h4 >{item.employee}</h4>

				<div className="shift-detail">
					<div className="detail-icon">
						<img src={item.image} alt="" />
					</div>
					<div className="detail-title">
						{item.title}
					</div>
				</div>
			</div>
		)
	}

	render() {
		return (
			<div className="WeekDay">
				<NavLink onClick={()=> this.props.responsiveVoice.speak(this.props.day, "Dutch Female", {volume: this.props.appData.volume})} to={'/shifts/' + this.props.dayId} className="nostyle" >
					<div className={"header color-day-"+this.props.dayId} >
						<div className="background-icon"></div>
						<h2 >{this.props.day}</h2>
					</div>
				</NavLink>

				{this.props.schedulesForDay ? Object.keys(this.props.schedulesForDay).map(this.renderSchedule) : ''}
            </div>
		)
	}
}

function mapStateToProps(state) {
    return {
    	appData: state.appData,
        responsiveVoice: state.appData.responsiveVoice
    };
}
export default connect(mapStateToProps)(WeekDay);
