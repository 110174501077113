import React from 'react';
import { connect } from 'react-redux';
class MenuContent extends React.Component{

	render() {
		return (

			<div className={this.props.hasClass + " content-el"} >
      	<div className="inner" >
					<div>{this.props.renderPopupButton ? <div className="popupbutton" ><i  onClick={this.props.showPopup} className="fa fa-info-circle" aria-hidden="true"></i></div> : ''} </div>
        	<div dangerouslySetInnerHTML={{ __html: this.props.content }} onClick={()=> this.props.responsiveVoice.speak(this.props.content.replace(/<(?:.|\n)*?>/gm, ''), "Dutch Female", {volume: this.props.appData.volume})}/>
            <img src={this.props.image} alt={''}/>

      	</div>
      </div>
		)
	}
}

function mapStateToProps(state) {
    return {
        appData: state.appData,
		responsiveVoice: state.appData.responsiveVoice
    };
}


export default connect(mapStateToProps)(MenuContent);
