import { connect } from 'react-redux';
import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; 


class ShiftBlock extends React.Component{

	render() {
		
		return (
            <div className={"col-"+this.props.colClass+"-holder"} onClick={()=> this.props.responsiveVoice.speak(this.props.item.employee + ', ' + this.props.item.title, "Dutch Female", {volume: this.props.appData.volume})}>
				<ReactCSSTransitionGroup transitionName="shifts" transitionAppear={true} transitionEnter={false} transitionLeave={false} transitionAppearTimeout={150000000} transitionEnterTimeout={0} transitionLeaveTimeout={0}>
					<div className="col-3"  key={this.props.item.id}>
                        <div className="block-image">
                            <img src={this.props.item.icon} alt={''}/>
                        </div>
		                <span className="block-title">{this.props.item.title}</span>
		                <span className="block-content">{this.props.item.employee}</span>
		                <div className="right-image" style={{backgroundImage: "url(" + this.props.item.image + ")"}}>

		                </div>
		            </div>
	            </ReactCSSTransitionGroup>
            </div>
		)
	}
}


function mapStateToProps(state) {
    return {
    	appData: state.appData,
		responsiveVoice: state.appData.responsiveVoice
    };
}


export default connect(mapStateToProps)(ShiftBlock);
