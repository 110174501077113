
//-- Import all components to application. Nice & clean.
import ReactDOM from 'react-dom';
import React from 'react';
import { HashRouter } from 'react-router-dom'
import Selector from './components/App/Selector';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { setClient } from './actions';
//BrowserRouter
Selector.oncontextmenu = function (e) {
    e.preventDefault();
};

const store = configureStore();

if(window.location.href.indexOf("abrona") > -1) {
   store.dispatch(setClient('abrona'));
}else if(window.location.href.indexOf("onstweedethuis") > -1){
	store.dispatch(setClient('onstweedethuis'));
}else{
   store.dispatch(setClient('philadelphia'));
}


ReactDOM.render((
	<Provider store={store}>
		<HashRouter>
			<Selector />
		</HashRouter>
	</Provider>
), document.getElementById('main'))
