import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; 

//style={{left : this.props.right + "%"}}
class MarqueeText extends React.Component{
  
  render() {

      return (
      	<div className="holder">
	      	<ReactCSSTransitionGroup transitionName="leftToRight" transitionAppear={true} transitionEnter={true} transitionLeave={true} transitionAppearTimeout={150000000} transitionEnterTimeout={0} transitionLeaveTimeout={0}>
		        <div className="marquee" >
		          <span ref="text">{this.props.text}a</span>
		        </div>
	        </ReactCSSTransitionGroup>
	    </div>
      );
  }
}

export default MarqueeText;