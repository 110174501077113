import React from 'react';
import { connect } from 'react-redux';
class MenuHeader extends React.Component{
	render() {
		return (
			<div onClick={()=> this.props.responsiveVoice.speak(this.props.title, "Dutch Female", {volume: this.props.appData.volume})} className={this.props.hasClass + " header-el"}>{this.props.title}</div>
		)
	}
}

function mapStateToProps(state) {
    return {
        appData: state.appData,
		responsiveVoice: state.appData.responsiveVoice
    };
}


export default connect(mapStateToProps)(MenuHeader);
