import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
class EmptyBlock extends React.Component{

	render() {

		return (
			<div className="col-3-holder" onClick={()=> this.props.responsiveVoice.speak("Er is nog geen "+this.props.type+" bekend vandaag.", "Dutch Female", {volume: this.props.appData.volume})}>
				<ReactCSSTransitionGroup transitionName="shifts" transitionAppear={true} transitionEnter={false} transitionLeave={false} transitionAppearTimeout={150000000} transitionEnterTimeout={0} transitionLeaveTimeout={0}>
					<div className="col-3">
		                <div className="left-image">
		                    <img src={''} alt={''}/>
		                </div>
		                <span className="block-title">Geen {this.props.type}</span>
		                <span className="block-content">Geen {this.props.type} bekend</span>

		            </div>
	            </ReactCSSTransitionGroup>
            </div>


		)
	}
}

function mapStateToProps(state) {
    return {
    	appData: state.appData,
        responsiveVoice: state.appData.responsiveVoice
    };
}
export default connect(mapStateToProps)(EmptyBlock);
