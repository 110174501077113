import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import DayList from '../Subcomponents/DayList';
import TaskBlock from '../Tasks/TaskBlock.js';

import { connect } from 'react-redux';
import {setIconAndName, setCurrentComponent} from '../../actions';
import EmptyBlock from './EmptyBlock';

class Tasks extends React.Component{
	componentDidMount () {

		this.props.setCurrentComponent({currentComponent:'tasks'});
		this.props.setIconAndName({routeName:'Taken', routeIcon:'//files.smartdevelopment.nl/'+this.props.client+'/wonen/icons/tasks.png'});
	}
	renderBlocks(blocks, from, to) {
		let aItems = [];
		let i = 1;
		for (let prop in blocks) {
			if(i <= to && i >= from) {
			    if (blocks.hasOwnProperty(prop)) {
			        let item = blocks[prop];
			        aItems.push(this.renderItem(item));
			    }
			}
			i += 1;
		}
		return aItems;
	}


    getDayNameFromNumber(dayNumber) {
        const arr = Object.values(this.props.appData.application.days);
        return arr[dayNumber - 1];
    }
	renderItem(item) {
		let colClass = parseInt(this.props.appData.application.extendTaskBlocks, 10) === 1 ? '2' : '3';
		let showClientPicture = this.props.appData.application.show_client_picture ;
		return <TaskBlock showClientPicture={showClientPicture} colClass={colClass} item={item} key={item.id + '_' + this.props.match.params.day}/>
	}
	render() {
		
		let fromFirst = 1;

		let toFirst = parseInt(this.props.appData.application.extendTaskBlocks, 10) === 1 ? 5 : 4;
		let fromSecond = parseInt(this.props.appData.application.extendTaskBlocks, 10) === 1? 6 : 5;
		let toSecond = parseInt(this.props.appData.application.extendTaskBlocks, 10) === 1? 10 : 8;
		let fromThird = parseInt(this.props.appData.application.extendTaskBlocks, 10) === 1 ? 11 : 10;
		let toThird = parseInt(this.props.appData.application.extendTaskBlocks, 10) === 1 ? 12 : 11;

		let headerClass = parseInt(this.props.appData.application.header_same_color, 10) === 1 ? "color-day-" + this.props.match.params.day : '';
		return (
			<span>
				<DayList color="pink" basePath="/tasks" />

			    <div id="tasksBody">
			    	<ReactCSSTransitionGroup transitionName="contentblock" transitionAppear={true} transitionEnter={false} transitionLeave={false} transitionAppearTimeout={150000000} transitionEnterTimeout={0} transitionLeaveTimeout={0}>
			        	<div  onClick={()=> this.props.responsiveVoice.speak(this.getDayNameFromNumber(this.props.match.params.day), "Dutch Female", {volume: this.props.appData.volume})} id="header" className={headerClass}>{this.getDayNameFromNumber(this.props.match.params.day)}</div>
			        </ReactCSSTransitionGroup>
			        <div className="row">
			        	{this.props.appData.application.tasks ? this.props.appData.application.tasks[this.props.match.params.day] ? this.renderBlocks(this.props.appData.application.tasks[this.props.match.params.day], fromFirst, toFirst) : <EmptyBlock /> : <EmptyBlock />}
			        </div>
			         <div className="row">
			        	{this.props.appData.application.tasks ? this.renderBlocks(this.props.appData.application.tasks[this.props.match.params.day], fromSecond, toSecond) : ''}
			        </div>
					<div className="row">
			        	{this.props.appData.application.tasks ? this.renderBlocks(this.props.appData.application.tasks[this.props.match.params.day], fromThird, toThird) : ''}
			        </div>

			    </div>
			</span>

		)
	}
}

function mapStateToProps(state) {
    return {
        appData: state.appData,
		responsiveVoice: state.appData.responsiveVoice,
		client: state.appData.client,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIconAndName: data => dispatch(setIconAndName(data)),
        setCurrentComponent: data => dispatch(setCurrentComponent(data)),

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
