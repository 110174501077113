import React from 'react';
import { connect } from 'react-redux';

class Netflix extends React.Component{

  render() {
      return (
        <div className="platform">
          <iframe src="https://netflix.com/"></iframe>
        </div>
      );
  }
}
function mapStateToProps(state) {
    return {
        appData: state.appData
    };
}
export default connect(mapStateToProps)(Netflix);